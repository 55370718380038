import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app" }

import FixedBackHome from "./components/common/FixedBackHome.vue";
import WapInter from "@haa/fwk-h5/components/wap-inter.vue";

import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { Haa, OeReportType, OeReportSubType } from "@haa/fwk-h5";
import { useControlStore } from "@/store/control";
import { storeToRefs } from "pinia";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute();

// 是否展示返回首页全局浮窗按钮
const showFixedBackHome = computed<boolean>(() => {
  // 刷新后 route.matched第一时间是空数组
  let flag = !!route.matched?.[0];

  // 判断路由表上是否携带隐藏返回首页icon字段
  if (route.matched?.[0]?.meta.hasOwnProperty("hideFixedHome")) {
    flag = route.matched[0].meta.hideFixedHome as boolean;
  }

  return flag;
});

// 根据答题数 是否展示插屏
const { showAnswerInter } = storeToRefs(useControlStore());

// 返回顶部按钮点击 收集行为
const onBackTop = () => {
  const { oeReport } = Haa.util().useOeReport();

  if (!route.name) return;

  oeReport(OeReportType.Action, OeReportSubType.ActionCollect, route.name as string);
};

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_van_back_top = _resolveComponent("van-back-top")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view),
    _createVNode(_component_van_back_top, {
      offset: "400",
      bottom: "15vh",
      onClick: onBackTop
    }),
    _withDirectives(_createVNode(FixedBackHome, null, null, 512), [
      [_vShow, showFixedBackHome.value]
    ]),
    (_unref(showAnswerInter))
      ? (_openBlock(), _createBlock(WapInter, {
          key: 0,
          "space-key": "AnswerInter"
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})