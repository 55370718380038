const Inter1 = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6965397",
    space_js: "//h5fpb1.sdlads.cn/source/x/srarp_k/static/a_t.js"
  }
];

const Banner1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6965398",
    space_js: "//h5fpb1.sdlads.cn/site/yts_b/openjs/sq/production/lb/common/v.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6965399",
    space_js: "//h5fpb1.sdlads.cn/common/avud_us_nd_d.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6965400",
    space_js: "//h5fpb1.sdlads.cn/common/i_dclc/a/z/resource/jj.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6965401",
    space_js: "//h5fpb1.sdlads.cn/common/je-dmd/source/b/openjs/a-kn.js"
  }
];

export const SPACE_MAP = {
  // 首页官方插屏
  HomeInter: Inter1,
  // 首页banner
  HomeBanner: Banner1,
  // 首页信息流
  HomeNative: Native1,
  HomeNativeCenter1: Native2,
  HomeNativeCenter2: Native3,
  // 菜谱详情页banner
  RecipeDetailBanner: Banner1,
  // 菜谱详情页信息流
  RecipeDetailNative: Native1,
  // 话题详情页banner
  TopicDetailBanner: Banner1,
  // 话题详情页信息流
  TopicDetailNative: Native1,
  // 百科详情页banner
  BaikeDetailBanner: Banner1,
  // 百科详情页信息流
  BaikeDetailNative: Native1,
  // 节气详情页banner
  SolarDetailBanner: Banner1,
  // 结果页官方插屏弹窗
  ResultInter: Inter1,
  // 答题官方插屏
  AnswerInter: Inter1,
  ListInter: Inter1,
  ListBanner: Banner1,
  ListNative: Native1,
  ListNativeCenter1: Native2,
  ListNativeCenter2: Native3
};