import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

import router from "@/router";


const __default__ = {
  name: "FeaturedTag"
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const onBackHome = () => {
  router.replace("/home");
};

return (_ctx: any,_cache: any) => {
  const _directive_click = _resolveDirective("click")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "fixed-back-home",
    onClick: onBackHome
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("span", null, "回到首页", -1)
  ]))), [
    [
      _directive_click,
      'FixedBackHomeBtn',
      "oeReport",
      { action: true }
    ]
  ])
}
}

})